import { createStore } from 'vuex';

const store = createStore({
  state: {
    siteConfig: null,
  },
  mutations: {
    setSiteConfig(state, config) {
      state.siteConfig = config;
    },
  },
  actions: {
    async fetchSiteConfig({ commit }) {
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}site-configuration?pLevel`);
        if (!response.ok) {
          throw new Error(`Erreur HTTP : ${response.status}`);
        }
        const data = await response.json();
        commit('setSiteConfig', data.data);
      } catch (error) {
        console.error('Erreur lors du chargement des configurations du site:', error);
      }
    },
  },
});

export default store;