import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import store from './store';
import './index.css'

const app = createApp(App)

// Configurer une instance d'Axios avec l'URL de base de Strapi
app.config.globalProperties.$axios = axios.create({
  baseURL: 'http://localhost:1337/api'
})

app.use(router);
app.use(store);
app.mount('#app');
