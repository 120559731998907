<template>
  <div>
    <!-- Charger dynamiquement le composant sélectionné et passer les données -->
    <component v-if="headerComponent" :is="headerComponent" :header-data="headerData" />
    <div v-else>Erreur lors du chargement du header</div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  props: {
    headerData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      headerComponent: null
    };
  },
  watch: {
    headerData: {
      immediate: true, // Appelle la méthode au chargement initial
      handler(newData) {
        if (newData) {
          this.selectHeader();
        }
      }
    }
  },
  methods: {
    selectHeader() {
      if (this.headerData && this.headerData.Type) {
        const headerType = this.headerData.Type;
        console.log("Header type : ", headerType);
        this.headerComponent = defineAsyncComponent(() => import('./' + headerType + ".vue"));
      } else {
        console.error("headerData is null or does not contain 'Type'");
      }
    }
  }
};
</script>