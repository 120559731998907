<template>
    <div :style="{ backgroundColor: backgroundColor, paddingTop: menuPaddingTop }" class="bg-white">
      <!-- Hero Section -->
      <div class="text-center py-16 px-6">
        <h1 :style="{ color: titleColor }" class="text-4xl font-bold mb-4">
          {{ blockData.Titre }}
        </h1>
        <p :style="{ color: textColor }" class="text-xl max-w-2xl mx-auto">
          {{ blockData.Texte }}
        </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      blockData: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        menuData: null,
      };
    },
    computed: {
      backgroundColor() {
        return this.blockData.Couleur_du_fond?.Couleur || '#fff';
      },
      titleColor() {
        return this.blockData.Couleur_du_titre?.Couleur || '#000';
      },
      textColor() {
        return this.blockData.Couleur_du_texte?.Couleur || '#000';
      },
      menuPaddingTop() {
        // Si le menu est "Par_Dessus", ajouter un padding-top basé sur la hauteur du menu
        return this.menuData?.Menu_principal.Par_dessus
          ? `${this.menuData.Menu_principal.Hauteur}px`
          : '0px';
      }
    },
    async created() {
      try {
        // Récupérer les données du menu principal
        const response = await fetch(`${process.env.VUE_APP_API_URL}menu-principal?pLevel`);
        const data = await response.json();
        this.menuData = data.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des données du menu principal:', error);
      }
    }
  };
  </script>