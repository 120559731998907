<template>
    <div>
      <h1>Article : {{ titre }}</h1>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ArticleComponent',  // Utiliser un nom multi-mots
    props: {
      slug: {
        type: String,
        required: true
      }
    }
  }
  </script>