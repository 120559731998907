import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'  // Assure-toi d'avoir ce composant ou modifie le chemin.
import Page from '../components/Page.vue'  // Composant pour les pages dynamiques.
import Article from '../components/Article.vue'  // Composant pour les articles dynamiques.
import Articles from '../components/Articles.vue' 

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:slug',
    name: 'Page',
    component: Page,
    props: true
  },
  { 
    path: '/articles', 
    name: 'Articles', 
    component: Articles, 
  },
  {
    path: '/article/:slug',
    name: 'Article',
    component: Article,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router