<template>
  <div :class="mainMenuClasses">
    <!-- Charger dynamiquement le composant sélectionné et passer les données -->
    <component v-if="mainMenuComponent" :is="mainMenuComponent" :main-menu-data="mainMenuData" />
    <div v-else>Erreur lors du chargement du menu</div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
  props: {
    mainMenuData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      mainMenuComponent: null
    };
  },
  computed: {
    mainMenuClasses() {
      let classes = 'w-full'; // Classe de base pour la largeur complète
      if (this.mainMenuData && this.mainMenuData.Par_dessus) {
        classes += ' absolute top-0 left-0';
      }
      if (this.mainMenuData && this.mainMenuData.Attacher_en_haut) {
        classes += ' fixed top-0 left-0';
      }
      return classes;
    }
  },
  watch: {
    mainMenuData: {
      handler(newValue) {
        if (newValue && Object.keys(newValue).length > 0) {
          this.selectMainMenu();
        }
      },
      immediate: true, // Exécute immédiatement au montage du composant
      deep: true
    }
  },
  methods: {
    selectMainMenu() {
      console.log("Chargement du menu avec les données :");
      console.log(this.mainMenuData);

      if (this.mainMenuData && this.mainMenuData.Type) {
        const mainMenuType = this.mainMenuData.Type;
        console.log("Main Menu type : ", mainMenuType);
        this.mainMenuComponent = defineAsyncComponent(() =>
          import('./' + mainMenuType + ".vue")
        );
      } else {
        console.error("mainMenuData ou Type est manquant, impossible de charger le composant");
      }
    }
  }
};
</script>