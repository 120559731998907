<template>
  <div id="app">
    <!-- Inclure le MainMenuSwitcher sur toutes les pages -->
    <MainMenuSwitcher :mainMenuData="mainMenuData" />
    
    <!-- Affiche le contenu des pages liées aux routes -->
    <router-view />
    
    <!-- Inclure le Footer sur toutes les pages -->
    <Footer :footerData="footerData" />
  </div>
</template>

<script>
import MainMenuSwitcher from './components/main-menu/MainMenuSwitcher.vue';
import Footer from './components/footer/Footer1.vue';

export default {
  components: {
    MainMenuSwitcher,
    Footer
  },
  data() {
    return {
      mainMenuData: null,
      footerData: null
    };
  },
  async created() {
    try {
      await this.$store.dispatch('fetchSiteConfig');
      
      // Récupérer les configurations du site et les données du menu principal depuis Strapi
      const configResponse = await fetch(`${process.env.VUE_APP_API_URL}site-configuration?pLevel`);
      const configData = await configResponse.json();
      const config = configData.data;
      console.log(config);

      // Récupérer les données du menu principal
      const menuResponse = await fetch(`${process.env.VUE_APP_API_URL}menu-principal?pLevel`);
      const menuData = await menuResponse.json();
      this.mainMenuData = menuData.data.Menu_principal;

      // Récupérer les données du footer
      const footerResponse = await fetch(`${process.env.VUE_APP_API_URL}pied-de-page?pLevel`);
      const footerData = await footerResponse.json();
      this.footerData = footerData.data;
      console.log("footer");
      console.log(this.footerData);

      // Appliquer les styles en variables CSS
      this.applyGlobalStyles(config);
    } catch (error) {
      console.error('Erreur lors de la récupération des configurations du site ou du menu principal :', error);
    }
  },
  methods: {
    applyGlobalStyles(config) {
      const root = document.documentElement;
      console.log("Taille 1:");
      console.log(config.Titre_1?.Taille);
      
      // Titre 1
      root.style.setProperty('--font-title-primary-size', config.Titre_1?.Taille + 'px');
      root.style.setProperty('--font-title-primary-mobile-size', config.Titre_1?.Taille_mobile + 'px');
      root.style.setProperty('--font-title-primary-line-height', config.Titre_1?.Hauteur_de_ligne + 'px');
      root.style.setProperty('--font-title-primary-family', config.Titre_1?.Famille);
    }
  }
};
</script>
