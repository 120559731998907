<template>
    <section class="py-20 bg-white" id="avis">
      <div class="container mx-auto px-6">
        <h2 class="text-3xl font-bold text-center mb-16 text-purple-900">{{ blockData.Titre }}</h2>
        <!-- Vérifie le nombre d'avis et affiche un carrousel ou une grille en fonction -->
        <div v-if="blockData.Avis.length > 3">
          <swiper
            :slides-per-view="1"
            :space-between="30"
            :breakpoints="{
              640: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 3 }
            }"
            :pagination="{ el: '.swiper-pagination', clickable: true }"
            :autoplay="{ delay: 1000, disableOnInteraction: false }"
            loop
          >
            <swiper-slide
              v-for="(avis, index) in blockData.Avis"
              :key="'avis-' + index"
              class="flex flex-col"
            >
              <div class="bg-purple-50 p-8 rounded-lg flex-grow flex flex-col">
                <div class="flex items-center mb-4">
                  <img
                    :src="avis.Image.url"
                    alt="Avatar"
                    class="rounded-full mr-4"
                    width="40"
                    height="40"
                  />
                  <div>
                    <h4 class="font-semibold text-purple-900">{{ avis.Nom }}</h4>
                    <p class="text-purple-600">{{ avis.Fonction }}</p>
                  </div>
                </div>
                <p class="text-gray-700 flex-grow">"{{ avis.Avis }}"</p>
              </div>
            </swiper-slide>
          </swiper>
          <!-- Ajout du conteneur pour la pagination -->
          <div class="swiper-pagination mt-4"></div>
        </div>
        <!-- Affiche une grille normale si le nombre d'avis est inférieur ou égal à 3 -->
        <div v-else class="grid md:grid-cols-3 gap-8">
          <div
            v-for="(avis, index) in blockData.Avis"
            :key="'avis-' + index"
            class="bg-purple-50 p-8 rounded-lg flex flex-col"
          >
            <div class="flex items-center mb-4">
              <img
                :src="avis.Image.url"
                alt="Avatar"
                class="rounded-full mr-4"
                width="40"
                height="40"
              />
              <div>
                <h4 class="font-semibold text-purple-900">{{ avis.Nom }}</h4>
                <p class="text-purple-600">{{ avis.Fonction }}</p>
              </div>
            </div>
            <p class="text-gray-700 flex-grow">"{{ avis.Avis }}"</p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/swiper-bundle.css';
  
  export default {
    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      blockData: {
        type: Object,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  /* Uniformiser la hauteur des éléments dans le carrousel */
  .swiper-slide {
    display: flex;
    justify-content: center;
    height: auto;
  }
  
  /* Assurez-vous que la pagination est visible et a une taille */
  .swiper-pagination {
    position: relative;
    width: 100%;
    text-align: center;
    bottom: 10px;
    z-index: 10;
  }
  
  .swiper-pagination-bullet {
    background: #4a148c;
    opacity: 0.6;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transition: opacity 0.3s;
  }
  
  .swiper-pagination-bullet-active {
    opacity: 1;
  }
  </style>
  