<template>
    <div>
        <h1>Liste des articles</h1>
        <ul v-if="articles.length">
            <li v-for="article in articles" :key="article.id">
                <h2>{{ article.Titre }}</h2>
                <!-- <p>{{ article.attributes.content }}</p> -->
            </li>
        </ul>
        <p v-else>Aucun article trouvé.</p>
    </div>
</template>

<script>
export default {
    name: 'ArticlesList',  // Utilise un nom multi-mots
    data() {
        return {
            articles: []
        }
    },
    created() {
        this.fetchArticles()
    },
    methods: {
        async fetchArticles() {
            try {
                const response = await this.$axios.get('/articles?populate=*')
                console.log('Données reçues de l\'API:', response.data)  // Affiche les données reçues
                this.articles = response.data.data
            } catch (error) {
                console.error('Erreur lors de la récupération des articles:', error)
            }
        }
    }
}
</script>