<template>
  <div>
    <header class="bg-gradient-to-r from-purple-700 to-indigo-900 text-white h-screen">
      <HeaderSwitcher :headerData="headerData"/>
      <!-- Contenu de la page d'accueil -->
    </header>
    <div>
      <component
        v-for="(block, index) in dynamicBlocks"
        :key="index"
        :is="getComponentName(block.__component)"
        :block-data="block"
      />
    </div>
  </div>
</template>

<script>
import HeaderSwitcher from './headers/HeaderSwitcher.vue';
import PourquoiNousChoisir from './pourquoi-nous-choisir/PourquoiNousChoisir1.vue';
import NosOffresSwitcher from './nos-offres/NosOffresSwitcher.vue';
import Avis from './avis/Avis1.vue';

export default {
  components: {
    HeaderSwitcher,
    PourquoiNousChoisir,
    NosOffresSwitcher,
    Avis
  },
  data() {
    return {
      headerData: null,
      dynamicBlocks: [] // Ajouter un tableau pour stocker les blocs de contenu
    };
  },
  async created() {
    try {
      const response = await fetch(`${process.env.VUE_APP_API_URL}accueil?pLevel`);
      if (!response.ok) {
        throw new Error(`Erreur HTTP : ${response.status}`);
      }
      const data = await response.json();
      this.headerData = data.data.Header;
      this.dynamicBlocks = data.data.Page; // Récupérer les blocs dynamiques
      console.log("data.data.Page");
      console.log(data.data.Page);
    } catch (error) {
      console.error('Erreur lors du chargement des données de la page d\'accueil:', error);
      this.headerType = 'Header1'; // Fallback par défaut
    }
  },
  methods: {
    // Méthode pour mapper les types de composants de Strapi aux composants Vue
    getComponentName(component) {
      const componentMap = {
        'composant-page.text-block': 'TextBlock',
        'pourquoi-nous-choisir.pourquoi-nous-choisir-1': 'PourquoiNousChoisir',
        'nos-offres.nos-offres-1': 'NosOffresSwitcher',
        'avis.avis-1': 'Avis'
      };
      return componentMap[component] || null;
    }
  }
};
</script>