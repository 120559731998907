<template>
    <section class="py-20 bg-white" id="services">
      <div class="container mx-auto px-6">
        <h2 class="text-3xl font-bold text-center mb-16 text-purple-900">{{ blockData.Titre }}</h2>
        <div class="grid md:grid-cols-3 gap-12">
          <div class="text-center">
            <div class="bg-purple-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
              <img v-if="blockData.Icone_1" :src="blockData.Icone_1.url" alt="Icone 1" class="w-8 h-8" />
            </div>
            <h3 class="text-xl font-semibold mb-4 text-purple-900">{{ blockData.Titre_1 }}</h3>
            <p class="text-gray-600">{{ blockData.Texte_1 }}</p>
          </div>
          <div class="text-center">
            <div class="bg-purple-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
              <img v-if="blockData.Icone_2" :src="blockData.Icone_2.url" alt="Icone 2" class="w-8 h-8" />
            </div>
            <h3 class="text-xl font-semibold mb-4 text-purple-900">{{ blockData.Titre_2 }}</h3>
            <p class="text-gray-600">{{ blockData.Texte_2 }}</p>
          </div>
          <div class="text-center">
            <div class="bg-purple-100 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
              <img v-if="blockData.Icone_3" :src="blockData.Icone_3.url" alt="Icone 3" class="w-8 h-8" />
            </div>
            <h3 class="text-xl font-semibold mb-4 text-purple-900">{{ blockData.Titre_3 }}</h3>
            <p class="text-gray-600">{{ blockData.Texte_3 }}</p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    props: {
      blockData: {
        type: Object,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  
  </style>
  