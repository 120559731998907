<template>
    <div>
      <!-- Charger dynamiquement le composant sélectionné et passer les données -->
      <component v-if="nosOffresComponent" :is="nosOffresComponent" :nos-offres-data="nosOffresData" />
      <div v-else>Erreur lors du chargement des offres</div>
    </div>
  </template>
  
  <script>
  import { defineAsyncComponent } from 'vue';
  
  export default {
    props: {
      nosOffresData: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        nosOffresComponent: null
      };
    },
    created() {
      this.selectNosOffres();
    },
    methods: {
      selectNosOffres() {
        const nosOffresType = this.nosOffresData.Type;
        console.log("Nos Offres type : ", nosOffresType);
        this.nosOffresComponent = defineAsyncComponent(() => import('./' + nosOffresType + ".vue"));
      }
    }
  };
  </script>