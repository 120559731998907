<template>
  <div>
    <!-- Affichage du contenu dynamique de la page -->
      <component
        v-for="(block, index) in dynamicBlocks"
        :key="index"
        :is="getComponentName(block.__component)"
        :block-data="block"
      />
  </div>
</template>

<script>
import SectionPrincipale1 from './section-principale/SectionPrincipale1.vue';
import NosOffres from './nos-offres/NosOffresSwitcher.vue';
export default {
  components: {
    SectionPrincipale1,
    NosOffres,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pageData: null,
      dynamicBlocks: []
    };
  },
  methods: {
    // Méthode pour mapper les types de composants de Strapi aux composants Vue
    getComponentName(component) {
      const componentMap = {
        'section-principale.section-principal-1': 'SectionPrincipale1',
        'tarif.tarif-1': 'Tarif',
      };
      return componentMap[component] || null;
    }
  },
  async created() {
    try {
      // Effectuer une requête pour récupérer la page correspondant au slug
      const response = await fetch(`http://localhost:1337/api/pages?filters[Slug][$eq]=${this.slug}&pLevel`);
      const data = await response.json();
      this.dynamicBlocks = data.data[0].Page;
      console.log("data.data[0].Page");
      console.log(data.data[0].Page);
      
      // Vérifier si la page a été trouvée
      if (data.data && data.data.length > 0) {
        this.pageData = data.data[0].Page;
      } else {
        this.$router.push('/404'); // Redirige vers une page 404 si le slug ne correspond à rien
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des données de la page:', error);
    }
  },
};
</script>