<template>
    <footer v-if="footerData" class="bg-purple-900 text-white py-16">
      <div class="container mx-auto px-6">
        <div class="grid md:grid-cols-4 gap-12">
          <!-- Colonne 1 - Logo et Slogan -->
          <div class="space-y-6">
            <div v-if="footerData.Logo" class="flex items-center space-x-2">
              <img
                :src="footerData.Logo.url"
                :alt="footerData.Logo.alternativeText || 'Logo'"
                class="w-8 h-8"
              />
            </div>
            <p v-if="footerData.Slogan" class="text-purple-200 leading-relaxed" v-html="footerData.Slogan"></p>
          </div>
  
          <!-- Colonne 2 - Services -->
          <div>
            <h4 class="text-lg font-bold mb-6">{{ footerData.Titre_col_1 || 'Nos Services' }}</h4>
            <ul class="space-y-4">
              <li v-for="(link, index) in footerData.Menu_col_1?.Liens" :key="'menu1-' + index">
                <a :href="link.Url" class="text-purple-200 hover:text-white transition">{{ link.Nom }}</a>
              </li>
            </ul>
          </div>
  
          <!-- Colonne 3 - Entreprise -->
          <div>
            <h4 class="text-lg font-bold mb-6">{{ footerData.Titre_col_2 || "L'entreprise" }}</h4>
            <ul class="space-y-4">
              <li v-for="(link, index) in footerData.Menu_col_2?.Liens" :key="'menu2-' + index">
                <a :href="link.Url" class="text-purple-200 hover:text-white transition">{{ link.Nom }}</a>
              </li>
            </ul>
          </div>
  
          <!-- Colonne 4 - Contact -->
          <div>
            <h4 class="text-lg font-bold mb-6">{{ footerData.Titre_col_3 || 'Contact' }}</h4>
            <ul class="space-y-4">
              <li v-if="footerData.Adresse" class="flex items-start space-x-3">
                <img
                  v-if="footerData.Icone_adresse"
                  :src="footerData.Icone_adresse.url"
                  alt="Icone Adresse"
                  class="w-6 h-6 text-purple-400"
                />
                <span class="text-purple-200">{{ footerData.Adresse }}</span>
              </li>
              <li v-if="footerData.Mail" class="flex items-center space-x-3">
                <img
                  v-if="footerData.Icone_mail"
                  :src="footerData.Icone_mail.url"
                  alt="Icone Mail"
                  class="w-6 h-6 text-purple-400"
                />
                <a :href="'mailto:' + footerData.Mail" class="text-purple-200 hover:text-white transition">{{ footerData.Mail }}</a>
              </li>
              <li v-if="footerData.Telephone" class="flex items-center space-x-3">
                <img
                  v-if="footerData.Icone_telephone"
                  :src="footerData.Icone_telephone.url"
                  alt="Icone Téléphone"
                  class="w-6 h-6 text-purple-400"
                />
                <a :href="'tel:' + footerData.Telephone.replace(/ /g, '')" class="text-purple-200 hover:text-white transition">{{ footerData.Telephone }}</a>
              </li>
            </ul>
          </div>
        </div>
  
        <!-- Copyright -->
        <div class="border-t border-purple-800 mt-12 pt-8">
          <p class="text-purple-200 text-center text-sm">© 2024 TellMeWebsites Solutions. Tous droits réservés.</p>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    props: {
      footerData: {
        type: Object,
        default: () => ({})
      }
    }
  };
  </script>
  